export const contentList = [
    {
        id: 1,
        name: 'Top',
    },
    {
        id: 2,
        name: 'About',
    },
    {
        id: 3,
        name: 'Works',
    },
    {
        id: 4,
        name: 'Skills',
    },
    {
        id: 5,
        name: 'Experience',
    },
];

export const info = '【β版】UI調整とバグ取り中';
