export const list = [
    {
        id: 1,
        title: '2012年2月～',
        details: [
            '主にGREE Mobage mixi などのブラウザ向けソーシャルゲームの新規開発、運営業務に従事。',
            '主にUI・UXのコーディング業務を担当してきました。また、2015年4月よりサブリーダーとして、UIディレクション作業を行っていました。',
            'また、スマホゲームのヘルプなどに入ることもあり、iOS/Androidのゲームアプリ開発・運営の経験もあります。',
        ],
    },
    {
        id: 2,
        title: '2012年2月～',
        details: [
            '主にGREE Mobage mixi などのブラウザ向けソーシャルゲームの新規開発、運営業務に従事。',
            '主にUI・UXのコーディング業務を担当してきました。また、2015年4月よりサブリーダーとして、UIディレクション作業を行っていました。',
            'また、スマホゲームのヘルプなどに入ることもあり、iOS/Androidのゲームアプリ開発・運営の経験もあります。',
        ],
    },
];
