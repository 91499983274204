/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
//css
import { jsx } from '@emotion/react';

const Flow = (props) => {
    return (
        <>
            {props.val.description}
        </>
    );
};

export default Flow;
